import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SurveyQuestionComponent } from './survey-question/survey-question.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [
    SurveyQuestionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgCircleProgressModule.forRoot({
      radius: 20,
      outerStrokeWidth: 6,
      outerStrokeColor: "#ffffff",
      showInnerStroke: false,
      animationDuration: 1500,
      animation: true,
      titleColor: "#ffffff",
      showUnits: false,
      showSubtitle: false,
      titleFontSize: "20",
      titleFontWeight: "bold",
      backgroundColor: "#6EC1E4 ",
      backgroundStroke: "#dddddd",
      backgroundStrokeWidth: 6,
      backgroundPadding: 2.75,
      outerStrokeLinecap: "square",
    })
  ],
  exports: [
    SurveyQuestionComponent
  ]
})
export class ComponentModule { }

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
 /* {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },*/
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: '',
    redirectTo: 'survey',
    pathMatch: 'full'
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule),
    canActivate: [AuthGuardService]
  },
  /*{
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then( m => m.OverviewPageModule),
    canActivate: [AuthGuardService]
  },*/
  {
    path: 'personal-data',
    loadChildren: () => import('./pages/personal-data/personal-data.module').then( m => m.PersonalDataPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'participation-finished',
    loadChildren: () => import('./pages/participation-finished/participation-finished.module').then( m => m.ParticipationFinishedPageModule),
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentConfigService {

    // baseUrl: string = 'https://backend.dag.disphere.cloud'
    baseUrl: string = 'https://surveybe.dind.disphere.cloud';

    constructor() {
        this.setEnvironment();
    }

    setEnvironment() {
        if (document.URL.indexOf(':8100') !== -1) {
            this.baseUrl = 'http://127.0.0.1:8080';
        }        
        if (document.URL.indexOf('survey.qa.disphere.cloud') !== -1) {
          this.baseUrl = 'https://surveybe.qa.disphere.cloud';
      }
    }
}

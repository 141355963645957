import { Injectable } from '@angular/core';
import { EnvironmentConfigService } from '../config/environment-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParticipationService {

  constructor(
    public environmentService: EnvironmentConfigService,
    private httpClient: HttpClient,
  ) { }

  initParticipation(inputURL: string) {
    return this.httpClient.post(`${this.environmentService.baseUrl}/api/init`, { url: inputURL }).toPromise()
  }

  saveParticipation(participation: any) {
    return this.httpClient.post(`${this.environmentService.baseUrl}/api/save`, participation).toPromise()
  }

  saveInterimParticipation(participation: any) {
    return this.httpClient.post(`${this.environmentService.baseUrl}/api/saveinterim`, participation).toPromise()
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss'],
})
export class SurveyQuestionComponent implements OnInit {

  @Input() surveyQuestion: any
  @Input() questionIndex: number
  @Output() navigateNext: EventEmitter<boolean> = new EventEmitter()

  progressPercentage = 0
  strokeColor = "#6EC1E4 "

  avgProgress = 0
  maxRange = 0;

  doShowAvg = false;

  constructor() { }

  ngOnInit() {
    if (this.surveyQuestion.value > -1)
      this.runDeterminateProgress();
    if (this.surveyQuestion.value > 0) {
      this.doShowAvg = true;
      this.runDeterminateProgress();
    }
  }

  showAvg() {
    return this.doShowAvg;
    //this.runDeterminateProgress();
    //return this.surveyQuestion.value > -1;
  }

  isFirst() {
    return this.questionIndex == 0;
  }

  onGradeChange() {
    this.strokeColor = "#ffffff";
    this.progressPercentage = 100;
    this.doShowAvg = true;
    this.runDeterminateProgress();
    setTimeout(() => this.navigateNext.emit(), 2000);
  }

  runDeterminateProgress() {
    this.maxRange = parseFloat(this.surveyQuestion.average.toFixed(1)) * 10
    for (let i = 0; i <= this.maxRange; i++) {
      this.setPercentBar(+i);
    }
  }

  setPercentBar(i: any) {
    setTimeout(() => {
      let apc = (i / this.maxRange)
      this.avgProgress = (apc * this.maxRange) / 100;
    }, 5 * i);
  }

}
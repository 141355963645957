import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AppContextService } from './app-context.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public appContext: AppContextService
  ) { }

  canActivate(): boolean {
    if (!this.appContext.context.participation.id) {
      this.router.navigate(["survey"]);
      return false;
    }
    return true;
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppContextService {

  context = {
    participation: {
      agree_privacypolicy: null,
      answers: null,
      branche: null,
      bundesland: null,
      campaign: null,
      companyname: null,
      num_employees: null,
      created_at: null,
      customer: null,
      email: null,
      phone: null,
      firstname: null,
      id: null,
      key: null,
      lastname: null,
      salutation: null,
      state: null,
      updated_at: null,
      agree_consent: null,
      agree_moreinformation: null,
      label_moreinformation: null
    }
  }

  constructor() { }
}

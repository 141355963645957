import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ParticipationService } from './services/participation.service';
import { AppContextService } from './services/app-context.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public participationService: ParticipationService,
    public appContext: AppContextService,
  ) {
    this.initializeApp();
    this.initParticipation(window.location.search);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async initParticipation(inputURL: string) {
    const response = await this.participationService.initParticipation(inputURL);
    console.log(response['data']); 
    this.appContext.context.participation = response['data'];
    this.appContext.context.participation.answers = JSON.parse(response['data']['answers']);
  }

}
